<template>
  <div class="apply1">
    <van-nav-bar
      title="订单详情"
      fixed
      z-index="999"
      placeholder
      style="width: 100%"
    >
      <template #left>
        <!-- <van-icon name="arrow-left" color="#000" @click="onClickLeft" /> -->
        <img
          src="../../../assets/other/back.png"
          style="width: 12px; height: 24px"
          @click="onClickLeft"
          alt=""
        />
      </template>
    </van-nav-bar>

    <div class="last-time-box" v-if="orderDetail && orderDetail.canPay">
      <img class="time-img" src="../../../assets/other/last_time.png" alt="" />
      <span class="order-status">待支付</span>
      <van-count-down :time="timeCompute(orderDetail.expireDate)">
        <template #default="timeData">
          <span class="last-time">（剩余支付时间：</span>
          <span class="last-time">{{ addZero(timeData.hours) }}:</span>
          <span class="last-time">{{ addZero(timeData.minutes) }}:</span>
          <span class="last-time">{{ addZero(timeData.seconds) }}</span>
          <span class="last-time">）</span>
        </template>
      </van-count-down>
    </div>

    <div :class="orderDetail.canPay ? 'box-new' : 'box'" v-if="orderDetail">
      <div class="cell-group">
        <div class="cell-box active">
          <span class="title">报名驾校</span>
          <span class="title">{{ schoolName }}</span>
        </div>
        <div class="cell-box active">
          <span class="title">班型</span>
          <span class="choose-box">
            <span class="title"
              >{{ orderDetail.classTypeStr }}-{{ orderDetail.className }}</span
            >
          </span>
        </div>
        <div class="cell-box active">
          <span class="title">专属老师</span>
          <a
            :href="'tel:' + orderDetail.kefuPhone"
            class="view-box"
            @click.stop="contactTeacher()"
          >
            <span class="choose-box">
              <span class="title">{{ orderDetail.kefuName }}</span>
            </span>
          </a>
        </div>
      </div>

      <div class="cell-group">
        <div class="cell-box active">
          <span class="title">姓名</span>
          <span class="title">{{ member.name }}</span>
        </div>
        <div class="cell-box active">
          <span class="title">身份证</span>
          <span class="title">{{ member.idNo }}</span>
        </div>
        <div class="cell-box active">
          <span class="title">手机号</span>
          <span class="title">{{ member.mobile }}</span>
        </div>
      </div>
    </div>

    <div class="agreement-box">
      <img class="select-img" :src="img" @click="chooseAgreement" />
      <span class="left-tex">已阅读并同意</span>
      <span class="right-tex" @click="lookAgreement">《线上报名协议》</span>
    </div>

    <div class="under-pay" @click="underPay">线下支付</div>

    <div class="submid-box" v-if="orderDetail">
      <span class="price-box">
        <span class="pay-unit">合计：</span>
        <span class="pay-price">¥{{ orderDetail.payPrice }}</span>
      </span>
      <span class="pay-btn" @click="payClick">立即支付</span>
    </div>

    <van-overlay :show="showAlert" :lock-scroll="false" z-index="99">
      <div class="alert-box">
        <div class="alert-view">
          <div class="alert-title">报名协议</div>
          <div class="alert-content-box">
            <div class="alert-content" v-html="agreement"></div>
          </div>
          <div class="alert-btn-box">
            <div class="alert-btn" @click="showAlertMethod">同意</div>
          </div>
        </div>
      </div>
    </van-overlay>

    <van-overlay :show="showLoading" :lock-scroll="false">
      <div class="loading-box">
        <van-loading size="24px" color="#fff" vertical>支付中...</van-loading>
      </div>
    </van-overlay>

    <div v-html="html"></div>
  </div>
</template>


<script src="http://res.wx.qq.com/open/js/jweixin-1.0.0.js" type="text/javascript"></script>

<script>
import {
  Row,
  Col,
  NavBar,
  List,
  Icon,
  Cell,
  CellGroup,
  Field,
  Dialog,
  Toast,
  Loading,
  Overlay,
  CountDown,
} from "vant";
import {
  OrderDetail_Get,
  ClassAgreement_Api,
  OrderPay_Api,
  OrderOfflinePay_Api,
  OrderAliPay_Api,
} from "@/api/order";
import { getLocalStorage } from "@/core/utils/local-storage";

import selectImage from "@/assets/other/select.png";
import unselectImage from "@/assets/other/unselect.png";
import { parse } from "qs";

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [NavBar.name]: NavBar,
    [List.name]: List,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Dialog.name]: Dialog,
    [Loading.name]: Loading,
    [Overlay.name]: Overlay,
    [CountDown.name]: CountDown,
  },

  props: {
    params: [Object],
  },

  computed: {
    img() {
      return this.isSelect ? selectImage : unselectImage;
    },
    timeCompute() {
      return function (value) {
        var startTime = value.replace(/-/g, "/"); // 需要将字符串日期中的 ‘-’ 替换成 ‘/’ ,
        let date = new Date();
        let newValue = Date.parse(startTime) - Date.parse(date);

        return newValue > 0 ? newValue : 0;
      };
    },
    addZero() {
      return function (value) {
        return value > 9 ? value : "0" + value;
      };
    },
  },

  data() {
    return {
      orderDetail: null,
      schoolName: "",
      member: {},
      offlineAlert: "",
      kefuPhone: "",
      kefuName: "",
      agreement: "",
      isSelect: true,
      html: "",
      canPay: true,
      showLoading: false,
      showAlert: false,
    };
  },

  destroyed() {
    console.log("destroyed");
  },
  created() {
    let query = parse(getLocalStorage("query"));

    this.$reqGet(ClassAgreement_Api, {
      ...query,
      type: 1,
    }).then((res) => {
      this.agreement = res.map.content;
    });

    this.$reqGet(OrderDetail_Get + this.$route.query.orderId, {
      ...query,
      classId: this.$route.query.classId,
    }).then((res) => {
      this.orderDetail = res.map.object;
      this.schoolName = res.map.schoolName;
      this.member = res.map.member;
      this.offlineAlert = res.map.offlineAlert;
      this.kefuName = res.map.kefuName
        ? res.map.kefuName
        : this.orderDetail.kefuName;
      this.kefuPhone = res.map.kefuPhone
        ? res.map.kefuPhone
        : this.orderDetail.kefuPhone;
    });
  },
  methods: {
    contactTeacher() {},

    chooseClass() {
      this.$router.push({ name: "apply" });
    },
    chooseAgreement() {
      this.isSelect = !this.isSelect;
    },
    showAlertMethod() {
      this.showAlert = false;
    },
    lookAgreement() {
      this.showAlert = true;
      // Dialog.alert({
      //   title: "报名协议",
      //   message: this.agreement,
      //   theme: "round-button",
      //   messageAlign: "left",
      // }).then(() => {});
    },
    //立即支付
    payClick() {
      if (!this.isSelect) {
        Toast("请同意并勾选报名协议");
        return;
      }
      this.toPay(this.$route.query.orderId);
    },
    //
    toPay(orderId) {
      if (!this.canPay) {
        return;
      }
      this.canPay = false;

      this.showLoading = true;

      if (/MicroMessenger/.test(window.navigator.userAgent)) {
        this.wxPay(orderId);
      } else {
        this.aliPay(orderId);
      }
    },

    wxPay(orderId) {
      this.$reqPost(OrderPay_Api + orderId, {
        pageUrl: "/register/apply/paySuccess/index",
      }).then((res) => {
        if (res.map.noOpenId) {
          window.location.href = res.map.url;
          this.showLoading = false;
        } else {
          let charge = res.map.charge;
          this.play_pay(charge);
        }
      });
    },

    aliPay(orderId) {
      this.$reqPost(OrderAliPay_Api + orderId, {
        pageUrl: "/register/apply/paySuccess/index",
      }).then((res) => {
        this.showLoading = false;
        this.canPay = true;
        this.html = res.data;
        this.$nextTick(() => {
          document.forms[0].submit();
        });
      });
    },
    play_pay(charge) {
      var self = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: charge.appId, //公众号名称，由商户传⼊入
          timeStamp: charge.timeStamp, //时间戳 这⾥里随意使⽤用了⼀一个值
          nonceStr: charge.nonceStr, //随机串
          package: charge.package,
          signType: charge.signType,
          paySign: charge.paySign,
        },
        function (res) {
          self.canPay = true;
          self.showLoading = false;
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            self.$router.push({
              name: "paySuccess",
              params: { orderId: this.$route.query.orderId },
            });
          } else {
            self.$router.push({
              name: "classDetail",
              params: { itemId: orderId },
            });
          }
        }
      );
    },
    //线下支付
    underPay() {
      if (!this.isSelect) {
        Toast("请同意并勾选报名协议");
        return;
      }

      let val = getLocalStorage("themeColor");

      Dialog.alert({
        title: "线下支付方式",
        message: this.offlineAlert,
        confirmButtonColor: val["themeColor"],
        theme: "round-button",
        messageAlign: "left",
      }).then(() => {
        this.toUnderPaySuccess();
      });
    },

    toUnderPaySuccess() {
      let that = this;
      var params = {
        ...this.member,
        classId: this.$route.query.classId,
      };
      that
        .$reqPost(OrderOfflinePay_Api + this.$route.query.orderId, params)
        .then((res) => {
          this.$router.replace({
            name: "paySuccess",
            params: {
              params: {
                name: that.kefuName,
                mobile: that.kefuPhone,
                isUnder: true,
                orderId: that.$route.query.orderId,
              },
            },
          });
        });
    },

    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less">
.loading-box {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.under-pay {
  color: #666666;
  font-size: 14px;
  text-decoration: underline;
  margin: 16px auto 96px auto;
  text-align: center;
}
.submid-box {
  height: 76px;
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0px;
  padding-bottom: 20px;
  background-color: white;
  justify-content: space-between;
  .price-box {
    display: flex;
    align-items: center;
    .pay-unit {
      color: #ff2525;
      font-size: 12px;
      margin-left: 33px;
    }
    .pay-price {
      color: #ff2525;
      font-weight: 600;
      font-size: 20px;
    }
  }

  .pay-btn {
    width: 199px;
    height: 44px;
    color: white;
    background: var(--theme-color);
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 500;
    margin-right: 16px;
  }
}
.agreement-box {
  margin: 24px auto 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .left-tex {
    color: #666666;
    font-size: 12px;
    margin-left: 4px;
  }
  .right-tex {
    color: var(--theme-color);
    font-size: 12px;
    text-decoration: underline;
  }

  .select-img {
    width: 12px;
    height: 12px;
  }
}

.cell-group {
  width: 343px;
  margin: 16px auto 0 auto;
  overflow: hidden;
  border-radius: 8px;
  background: linear-gradient(180deg, #f7f7f7 0%, #ffffff 100%);
  border: 1px solid #999999;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;

  .cell-box {
    height: 52px;
    margin: 0 8px;
    width: 327px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 14px;
      color: #333333;
    }
    .title-red {
      color: var(--theme-color);
      font-size: 14px;
    }
    .view-box {
      display: flex;
      align-items: center;
    }
  }
  .cell-box.active {
    border-bottom: 1px solid #e5e5e5;
  }
  .choose-box {
    display: flex;
    align-items: center;
  }
}

.custom-title {
  font-size: 14px;
  color: #333333;
}
.apply1 {
  width: 100%;

  .last-time-box {
    display: flex;
    align-items: center;
    margin: 20px 0 0 16px;
    .time-img {
      height: 12px;
      width: 12px;
    }
    .order-status {
      color: #333333;
      font-size: 14px;
      margin: 0 4px;
    }
    .last-time {
      color: #ff2525;
      font-size: 12px;
    }
  }

  .alert-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .alert-view {
      width: 343px;
      min-height: 300px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      max-height: 80vh;
      background-color: white;

      .alert-title {
        min-height: 46px;
        width: 100%;
        border-bottom: 1px solid #e5e5e5;
        text-align: center;
        color: #333333;
        font-weight: 500;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .alert-content-box {
        display: block;
        flex: 1;
        .alert-content {
          overflow-x: hidden;
          margin: 16px;
          // -webkit-box-flex: 1;
          max-height: 50vh;
          // flex: 1;
          // width: 311px;
          // font-size: 14px;
          overflow-y: auto;
          // font-size: 14px;
          // white-space: pre-wrap;
          // word-wrap: break-word;
          // -webkit-overflow-scrolling: touch;
        }
      }

      .alert-btn-box {
        min-height: 72px;
        width: 100%;
        border-top: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
        justify-content: center;
        .alert-btn {
          width: 199px;
          height: 44px;
          background: var(--theme-color);
          border-radius: 7px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-weight: 500;
          color: white;
        }
        .time-btn {
          width: 199px;
          height: 44px;
          background-color: #999999;
          border-radius: 7px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-weight: 500;
          color: white;
        }
      }
    }
  }
  .box {
    padding: 16px 0;
    margin: 8px auto;
    width: 100%;
    background-color: white;
  }
  .box-new {
    padding: 0 0 16px 0;
    margin: 0 auto 8px auto;
    width: 100%;
    background-color: white;
  }
}
</style>
